import { NewInboxAdminI } from "@/interfaces/Inbox";
import { notifyError } from "@/utils/NotificationFunctions";
import axios from "axios";
import { celebApi } from "./api";

export async function getUserComplement(userId: string) {
  return axios
    .get(`${celebApi}/user/userProfileComplementation/${userId}`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      notifyError("Connection error");
      return error;
    });
}

export async function sendMessageToAdmin(
  inboxData: NewInboxAdminI,
  token: string
) {
  const httpOptions = {
    headers: {
      Authorization: token,
    },
  };

  return axios
    .post(`${celebApi}/user/newMessageToAdmin`, { inboxData }, httpOptions)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function seenAllNotifications(userToken: string) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };

  return axios
    .get(`${celebApi}/user/seenAllNotifications`, httpOptions)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function notShowAgainGallery(userToken: string) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };

  return axios
    .post(`${celebApi}/user/notShowAgainGallery`, {}, httpOptions)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function incrementPageAndViewCount(
  userId: string = "",
  userToken: string
) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };

  return axios
    .post(`${celebApi}/user/incrementPageAndViewCount`, { userId }, httpOptions)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
}
