"use client";
import { ReactNode, useEffect, useState } from "react";
import "./VisitValidation.scss";
import LoadingPage from "../LoadingPage/LoadingPage";
import makeAsyncScriptLoader from "react-async-script";
import ReCAPTCHA from "react-google-recaptcha";
import { validateCaptcha } from "@/services/GoogleService";
import { googleApi } from "@/services/api";
const cantina = "../../assets/images/cantina.jpeg";
const AsyncRecaptcha = makeAsyncScriptLoader(
  "https://www.google.com/recaptcha/api.js"
)(ReCAPTCHA);
const kalebLogo = "../../assets/images/LOGO.svg";
function VisitValidation({
  children,
  captchaKey,
}: {
  children: ReactNode;
  captchaKey: string;
}) {
  const [validation, setValidation] = useState<boolean>(false); //prevent yoda
  const [loading, setLoading] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [captchaResetKey, setCaptchaResetKey] = useState<number>(0);
  const handleRecaptcha = async (value: any) => {
    if (value) {
      try {
        const res = await validateCaptcha(value);
        if (res.success) {
          const userAgent = navigator.userAgent;
          localStorage.setItem("userAgent", userAgent);
          window.location.reload();
        } else {
          console.error(
            "Captcha verification failed or suspicious activity detected."
          );
          setCaptchaResetKey((prev) => prev + 1);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onRecaptchaLoaded = () => {
    console.log("captcha loaded");
    setLoaded(true);
  };

  useEffect(() => {
    const savedUserAgent = localStorage.getItem("userAgent");
    if (savedUserAgent === navigator.userAgent) {
      setValidation(true);
    }
    setLoading(false);
  }, []);

  // if (!validation) {
  //   return (
  //     <div className="visit-validation-main-container">
  //       <img className="celeb" src={kalebLogo} alt="logo" />
  //       <div className="overlay">
  //         <div className="text-container">
  //           <p>CelebStory is a Wiki-based resource.</p>
  //           <p>
  //             Each photo is attached to a date and displayed in chronological
  //             order as a smooth story.
  //           </p>
  //           <p>Upload photos or share your referral link to gain points.</p>
  //           <p>Later these points will be converted into our Crypto Coins,</p>
  //           <p>
  //             supported by the value of the whole project, and can be sold for
  //             profit.
  //           </p>
  //           <hr></hr>
  //           <p style={{ textDecoration: "underline" }}>
  //             Check ‘About’ for the details.
  //           </p>
  //           {/*	<p>Points can be spent on uploading to the Special category.</p>*/}
  //         </div>
  //         <img src={cantina} alt="cantina star wars" className="cantina" />
  //       </div>

  //       <AsyncRecaptcha
  //         key={captchaResetKey}
  //         sitekey={captchaKey}
  //         onChange={handleRecaptcha}
  //         asyncScriptOnLoad={() => console.log("Script loaded")}
  //       />

  //       <div className="video-container">
  //         <iframe
  //           src="https://www.youtube.com/embed/XgopmhDaqgc?si=nv4o_3lZJQ0flSdp"
  //           title="YouTube video player"
  //           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  //           allowFullScreen
  //         ></iframe>
  //       </div>
  //     </div>
  //   );
  // }
  // return <>{children}</>;
  return (
    <>
      {loading && <LoadingPage />}
      {!validation && (
        <div className="visit-validation-main-container">
          <img className="celeb" src={kalebLogo} alt="logo" />
          <div className="overlay">
            <div className="text-container">
              <p>CelebStory is a Wiki-based resource.</p>
              <p>
                Each photo is attached to a date and displayed in chronological
                order as a smooth story.
              </p>
              <p>Upload photos or share your referral link to gain points.</p>
              <p>Later these points will be converted into our Crypto Coins,</p>
              <p>
                supported by the value of the whole project, and can be sold for
                profit.
              </p>
              <hr></hr>
              <p style={{ textDecoration: "underline" }}>
                Check ‘About’ for the details.
              </p>
              {/*	<p>Points can be spent on uploading to the Special category.</p>*/}
            </div>
            <img src={cantina} alt="cantina star wars" className="cantina" />
          </div>
          <AsyncRecaptcha
            key={captchaResetKey}
            sitekey={captchaKey}
            onChange={handleRecaptcha}
            asyncScriptOnLoad={() => console.log("Script loaded")}
          />
          <div className="video-container">
            <iframe
              src="https://www.youtube.com/embed/XgopmhDaqgc?si=nv4o_3lZJQ0flSdp"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
      {children}
    </>
  );
}

export default VisitValidation;
