import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/@next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Orbitron\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"]}],\"variableName\":\"orbitron\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/opt/build/repo/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/layout.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/components/VisitValidation/VisitValidation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/context/InboxContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/context/SocketContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/context/ThemeContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/context/UserContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/layout/MainLayout/MainLayout.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/index.scss");
